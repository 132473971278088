/*======================================= 
    MAP GraphQL object to Simple object
=========================================*/
import {KenticoChoice,KenticoImage} from './KontentTypes'
import {convertKontentColorToHex} from '@utils/Helpers'

type FeatureProductType ={
    elements:{
        url_slug: {
            value:string
        },
        icon:{
            value:KenticoImage[]
        },
        title:{
            value: string
        },
        sub_title:{
            value:string
        }
    }
}

const mapToFeatureProduct = (feature:FeatureProductType) =>{
    const {url_slug,icon,title,sub_title} = feature.elements;
    return {
        url_slug: url_slug.value,
        icon: icon.value[0].url,
        title: title.value,
        sub_title: sub_title.value
    };
};

export const mapToFeatureProducts = (features: FeatureProductType[]) =>{
    const mappedProducts = features.map(feature =>mapToFeatureProduct(feature));
    return mappedProducts;
};

type KenticoVehicleType ={
    node:{
        elements:{
            name:{
                value:string
            }
            icon:{
                value:KenticoImage[]
            }
        }
    }
}

const mapToVehicleType = (vehicleType:KenticoVehicleType) =>{
    const {name, icon} = vehicleType.node.elements;

    // const vehicleIcon = icon.value[0].url;
    return {
        name: name.value,
        icon: icon.value[0].url
    };
};

export const mapToVehicleTypes = (vehicleTypes:KenticoVehicleType[]) =>{
    return vehicleTypes.map(vehicleType=>mapToVehicleType(vehicleType));
};


type ButtonType = {
    buttonText: { value: string };
    buttonButtonColorTheme: { value: KenticoChoice[] };
    buttonButtonType: { value: KenticoChoice[] };
    buttonUrlSlug: { value: string };
}

export const mapToButton = (elements:ButtonType)=>{
    const {buttonText, buttonButtonColorTheme, buttonButtonType, buttonUrlSlug} = elements;
    return {
        url: buttonUrlSlug.value,
        text: buttonText.value,
        theme: convertKontentColorToHex(buttonButtonColorTheme.value[0].codename),
        type: buttonButtonType.value[0].codename
    }
}
export const mapToCheckoutStep = (elements:any) =>{
    const {pageInfoCheckoutProgress} = elements;
    const value = pageInfoCheckoutProgress.value[0].codename;
    const step = value.substring(1);
    return Number(step);
}